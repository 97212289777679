<template>
    <section class="" :class="{'mobile-layout' :is_screen_small, 'blog-section' : !is_screen_small }">  
                <v-flex xs9 sm9 offset-sm2 class="mt-10">

                        <v-layout justify-space-around justify-center  class="blog-layout">
                            <v-card tile flat class="blog-parallax" width="100%" :class="{'blog-parallax-mobile' :is_screen_small}">
                                   <v-row no-gutters class="blog-row-holder">
                                        <v-col lg="3" md="3" sm="12" xs="12" class="hidden-sm-and-down">
                                            <v-card flat tile height="100%"  column align="right" justify="right"  class="transparent">
                                                <v-card-text class="">
                                                    <v-card tile flat class="parallax-blog-text transparent">
                                                        <img :src="`${s3Path}whiteRabbit.png`" height="200" class="">
                                                    </v-card>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col lg="9" md="9" sm="12" xs="12" cols="12">
                                            <v-card  flat tile xs12 sm12  width="100%" class="transparent parallax-blog-text " :class="{'blog-text' :!is_screen_small}" dark>
                                                <v-card-text class="" :class="{'blog-slider-text' :is_screen_small}">
                                                <span class="zamu-heading slider-about-header"><span class="slider-subheader">safely </span>transact </span>
                                                <span class="zamu-normal slider-about-header"><span class="slider-subheader">globally with </span> zamupay </span>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    
                                    </v-row>
                            </v-card>
                        </v-layout>
                </v-flex>

        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';

export default {
        mixins: [colorMixin, coreMixin],
}
</script>

<style>

.blog-layout {
     background-image: url('../../../assets/images/galaxy.png') !important;
    background-position: center center !important;
}

</style>